<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link class="brand-logo">
                <b-img
                    :src="logo"
                    class="brand-logo-image"
                    alt="soclib logo"
                    width="120"
                />
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div
                    class="
                        w-100
                        d-lg-flex
                        align-items-center
                        justify-content-center
                        px-5
                    "
                >
                    <b-img
                        class="side-image"
                        fluid
                        :src="imgUrl"
                        alt="Register V2"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Register-->
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <!-- alert -->
                    <div v-if="alertMessages.length > 0">
                        <b-alert :variant="alertType" :show="alertVisibility">
                            <div class="alert-body">
                                <span
                                    class="d-block"
                                    v-for="(
                                        alertMessage, index
                                    ) in alertMessages"
                                    :key="index"
                                    :style="
                                        alertMessage.length - 1 == index
                                            ? 'margin-top: 4px'
                                            : ''
                                    "
                                >
                                    <small v-html="alertMessage"></small>
                                </span>
                            </div>
                        </b-alert>
                    </div>
                    <!-- alert -->
                    <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
                        Adventure starts here
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Make your app management easy and fun!
                    </b-card-text>

                    <!-- form -->
                    <validation-observer
                        ref="registerForm"
                        #default="{ invalid }"
                    >
                        <b-form
                            class="auth-register-form mt-2"
                            @submit.prevent="register"
                        >
                            <b-row>
                                <!-- first name -->
                                <b-col cols="12" lg="12" xl="6">
                                    <b-form-group
                                        label="First Name"
                                        label-for="register-firstname"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="FirstName"
                                            vid="firstname"
                                            rules="required"
                                        >
                                            <b-form-input
                                                autocomplete="off"
                                                id="register-firstname"
                                                v-model="firstName"
                                                name="register-firstname"
                                                :state="
                                                    errors.length > 0
                                                        ? false
                                                        : null
                                                "
                                                placeholder="John"
                                            />
                                            <small class="text-danger">{{
                                                errors[0]
                                            }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <!-- last name -->
                                <b-col cols="12" lg="12" xl="6">
                                    <b-form-group
                                        label="Last Name"
                                        label-for="register-lastname"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="LastName"
                                            vid="lastname"
                                            rules="required"
                                        >
                                            <b-form-input
                                                autocomplete="off"
                                                id="register-lastname"
                                                v-model="lastName"
                                                name="register-firstname"
                                                :state="
                                                    errors.length > 0
                                                        ? false
                                                        : null
                                                "
                                                placeholder="Doe"
                                            />
                                            <small class="text-danger">{{
                                                errors[0]
                                            }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <!-- linkedin -->
                            <b-form-group
                                label="Linkedin"
                                label-for="register-linkedin"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Linkedin"
                                    vid="linkedin"
                                >
                                    <b-form-input
                                        autocomplete="off"
                                        id="register-linkedin"
                                        v-model="linkedin"
                                        name="register-linkedin"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        placeholder="https://linkedin.com/in/johndoe"
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- birthdate -->
                            <b-form-group
                                label="Birth Date"
                                label-for="birthdate"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="birthdate"
                                    vid="birthdate"
                                >
                                    <b-form-datepicker
                                        :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }"
                                        id="birthdate"
                                        v-model="birthdate"
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- phone -->
                            <b-row>
                                <b-col cols="12" lg="12" xl="4">
                                    <b-form-group
                                        label="Country"
                                        label-for="register-phone"
                                    >
                                        <v-select
                                            v-model="country"
                                            dir="ltr"
                                            label="code"
                                            :options="countryOptions"
                                        >
                                            <template #no-options>
                                                Country Not Found.
                                            </template>
                                        </v-select>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" lg="12" xl="8">
                                    <!-- phone -->
                                    <b-form-group
                                        label="Phone"
                                        label-for="register-phone"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Phone"
                                            vid="phone"
                                        >
                                            <b-input-group
                                                :prepend="country.dial_code"
                                            >
                                                <b-form-input
                                                    type="number"
                                                    autocomplete="off"
                                                    id="register-username"
                                                    v-model="phone"
                                                    name="register-phone"
                                                    :state="
                                                        errors.length > 0
                                                            ? false
                                                            : null
                                                    "
                                                    placeholder=""
                                                />
                                            </b-input-group>
                                            <small class="text-danger">{{
                                                errors[0]
                                            }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <!-- username -->
                            <b-form-group
                                label="Username"
                                label-for="register-username"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Username"
                                    vid="username"
                                    rules="required"
                                >
                                    <b-form-input
                                        autocomplete="off"
                                        id="register-username"
                                        v-model="username"
                                        name="register-username"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        placeholder="johndoe"
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- email -->
                            <b-form-group
                                label="Email"
                                label-for="register-email"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Email"
                                    vid="email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        autocomplete="off"
                                        id="register-email"
                                        v-model="email"
                                        name="register-email"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        placeholder="john@example.com"
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- password -->
                            <b-form-group
                                label-for="register-password"
                                label="Password"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Password"
                                    vid="password"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="
                                            errors.length > 0
                                                ? 'is-invalid'
                                                : null
                                        "
                                    >
                                        <b-form-input
                                            autocomplete="off"
                                            id="register-password"
                                            v-model="password"
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            :state="
                                                errors.length > 0 ? false : null
                                            "
                                            name="register-password"
                                            placeholder="············"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                :icon="passwordToggleIcon"
                                                class="cursor-pointer"
                                                @click="
                                                    togglePasswordVisibility
                                                "
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- terms and conditions -->
                            <div class="d-flex align-items-center mb-2">
                                <b-form-checkbox
                                    v-model="TermsAndConditionsCheck"
                                    :value="true"
                                    plain
                                    id="terms-and-conditions"
                                >
                                </b-form-checkbox>
                                <label for="terms-and-conditions" class="mb-0">
                                    <small>
                                        Terms And Conditions
                                        <b-button
                                            variant="link"
                                            size="sm"
                                            v-b-modal.terms-and-conditions-modal
                                        >
                                            Read More ...
                                        </b-button>
                                    </small>
                                </label>
                            </div>
                            <!-- / terms and conditions -->
                            <b-button
                                variant="primary"
                                block
                                type="submit"
                                :disabled="
                                    !TermsAndConditionsCheck || isInProcess
                                "
                            >
                                <b-spinner v-if="isInProcess" small />
                                Sign up
                            </b-button>
                            <b-button
                                v-if="resendVerificationFlag"
                                class="mt-2"
                                block
                                variant="outline-secondary"
                                @click="resendVerificationHandler"
                            >
                                Resend Verification
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <p class="text-center mt-2">
                        <span>Already have an account?</span>
                        <b-link :to="{ name: 'auth-login' }">
                            <span>&nbsp;Sign in instead</span>
                        </b-link>
                    </p>
                </b-col>
            </b-col>
            <!-- /Register-->
        </b-row>
        <b-modal
            id="terms-and-conditions-modal"
            title="Terms And Conditions"
            hide-footer
        >
            <b-card-text
                class="mb-2"
                v-for="TermsAndCondition in TermsAndConditions"
                :key="TermsAndCondition.id"
            >
                <h5>{{ TermsAndCondition.title }}</h5>
                <p class="mb-0" v-html="TermsAndCondition.description"></p>
            </b-card-text>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
    BRow,
    BCol,
    BLink,
    BButton,
    BSpinner,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BAlert,
    BImg,
    BCardTitle,
    BCardText,
    BFormDatepicker,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import _ from "lodash";
import vSelect from "vue-select";
import useJwt from "@/auth/jwt/useJwt";
import { heightFade } from "@core/directives/animations";
import axios from "axios";

export default {
    directives: {
        "height-fade": heightFade,
    },
    components: {
        BAlert,
        BRow,
        BImg,
        BCol,
        BLink,
        BButton,
        BSpinner,
        BForm,
        vSelect,
        BCardText,
        BCardTitle,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormDatepicker,
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            status: "",
            username: "",
            email: "",
            password: "",
            firstName: "",
            lastName: "",
            linkedin: "",
            birthdate: "",
            phone: "",
            country: {},
            countryOptions: [],

            // validation
            required,
            email,

            // alert properties
            alertMessages: [],
            alertType: undefined,
            alertVisibility: false,

            // button loading animation flag
            isInProcess: false,

            // resend button flag
            resendVerificationFlag: false,

            // terms and conditions
            TermsAndConditionsCheck: false,
            TermsAndConditions: [],
        };
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon";
        },
        logo() {
            return require("@/assets/images/logo/logo_158.png");
        },
        imgUrl() {
                return require("@/assets/images/logo/logo_158.png");
        },
    },
    created() {
        this.getCountries();
        this.getTermsAndConditions();
    },
    methods: {
        getCountries() {
            axios
                .get(`${process.env.VUE_APP_URL}/countries`)
                .then(({ data }) => {
                    this.countryOptions = data;
                });
        },
        register() {
            this.$refs.registerForm.validate().then((success) => {
                if (success) {
                    this.isInProcess = true;

                    // const phonePrepend = this.country
                    //     ? this.country.dial_code.replace(/\+/g, "")
                    //     : undefined;

                    const params = {
                        first_name: this.firstName,
                        last_name: this.lastName,
                        birth_date: this.birthdate,
                        country: this.country ? this.country.code : undefined,
                        // phone_number: phonePrepend
                        //     ? `${phonePrepend}${this.phone}`
                        //     : this.phone,
                        phone_number : this.phone,
                        linkedin: this.linkedin,
                        email: this.email,
                        username: this.username,
                        password: this.password,
                    };

                    console.log(params);

                    useJwt
                        .register(params)
                        .then((response) => {
                            this.alertType = "success";
                            this.alertVisibility = true;
                            this.alertMessages = [
                                "Verification link has been sent to your email account",
                            ];
                            this.isInProcess = false;

                            setTimeout(() => {
                                this.resendVerificationFlag = true;
                            }, 9000);
                        })
                        .catch((error) => {
                            this.alertVisibility = true;
                            this.alertType = "danger";
                            this.alertMessages = _.flatten(
                                Object.values(error.response.data)
                            );
                            this.isInProcess = false;
                        });
                }
            });
        },
        getTermsAndConditions() {
            this.$http
                .get(`${process.env.VUE_APP_URL}/terms-and-conditions`)
                .then((response) => {
                    this.TermsAndConditions = response.data;
                });
        },
        resendVerificationHandler() {
            const params = {
                email: this.email,
            };

            this.$http.post(
                `${process.env.VUE_APP_URL}/verification-notification`,
                params
            );
            this.alertType = "success";
            this.alertVisibility = true;
            this.alertMessages = ["Verification link resent"];
            setTimeout(() => {
                this.resendVerificationFlag = false;
            }, 5000);
        },
    },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.side-image {
    width: 400px !important;
}
</style>
